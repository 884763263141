import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path:'/Facts',
    name: 'Facts',
  },
  {
    path:'/Persons',
    name: 'Persons'
  },
  {
    path:'/Organizations',
    name: 'Organizations'
  },
  {
    path:'/Geography',
    name: 'Geography'
  },
  {
    path:'/Objects',
    name: 'Objects'
  },
]
// const routes = [
//   {
//   //   path: '/',
//   //   name: 'Home',
//   //
//   // },
//   // {
//   //   path: '/about',
//   //   name: 'About',
//   //   // route level code-splitting
//   //   // this generates a separate chunk (about.[hash].js) for this route
//   //   // which is lazy-loaded when the route is visited.
//   //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   }
// ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
